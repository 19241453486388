import { useForm } from 'react-hook-form';
import { useEffect, useMemo, useState } from 'react';
import saveUpdatedUser from '../api/saveUpdatedUser';
import { format, parseISO } from 'date-fns';

const userStatusLabels = {
    active: 'Activo',
    blocked: 'Bloqueado',
    pending: 'Pendiente de validación de correo'
};

const documentTypesLabels = {
    dui: 'DUI',
    passport: 'Pasaporte'
};

export default function useUserDetails(
    selectedUser,
    onSuccessfulSave,
    selectionMode,
    userStatusCatalog,
    documentTypes
) {
    const [showSuccessModal, setShowSuccessModal] = useState(false);
    const [showErrorModal, setShowErrorModal] = useState(false);
    const [errorBody, setErrorBody] = useState('');
    const [successBody, setSuccessBody] = useState('');

    const {
        register,
        handleSubmit,
        formState: { errors, isDirty },
        watch,
        // control,
        reset,
        setValue
    } = useForm();

    useEffect(() => {
        reset({ ...selectedUser, creation: format(parseISO(selectedUser.creation), 'yyyy-MM-dd H:mm:ss') });
    }, [selectedUser, reset]);

    const _createErrorMessage = (errorMessage) => {
        setShowErrorModal(true);
        setErrorBody(errorMessage);
    };

    const closeErrorMessage = () => {
        setShowErrorModal(false);
        setErrorBody('');
    };

    const _createSuccessMessage = (successMessage) => {
        setShowSuccessModal(true);
        setSuccessBody(successMessage);
    };

    const onCloseSuccessMessage = () => {
        setShowSuccessModal(false);
        setSuccessBody('');
        onSuccessfulSave();
    };

    const onSaveComplete = async (data) => {
        const executeSaveComplete = await saveUpdatedUser(
            data.id,
            {
                ...data,
                idDoctorUserStatus: parseInt(data.idDoctorUserStatus),
                idDocumentTypes: parseInt(data.idDocumentTypes)
            },
            _createErrorMessage
        );

        if (executeSaveComplete) {
            _createSuccessMessage('¡Se ha actualizado el registro con éxito!');
        }
    };

    const onSaveSubmit = (data) => {
        onSaveComplete(data);
        return;
    };

    const documentTypesOptions = useMemo(() => {
        return (
            documentTypes &&
            documentTypes.map((item) => ({
                id: item.id,
                label: documentTypesLabels[item.documentName]
            }))
        );
    }, [documentTypes]);

    const userStatusOptions = useMemo(() => {
        return (
            userStatusCatalog &&
            userStatusCatalog.map((item) => ({
                id: item.id,
                label: userStatusLabels[item.statusName]
            }))
        );
    }, [userStatusCatalog]);

    return {
        register,
        handleSubmit,
        errors,
        watch,
        reset,
        setValue,
        isDirty,
        closeErrorMessage,
        onCloseSuccessMessage,
        showSuccessModal,
        showErrorModal,
        errorBody,
        successBody,
        onSaveComplete,
        onSaveSubmit,
        documentTypesOptions,
        userStatusOptions
    };
}
