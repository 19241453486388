import { Routes, Route, Navigate } from 'react-router-dom';
import { useState } from 'react';
import { Users } from '../../../../pages/Users';
import { Sidebar } from '../Sidebar';
import { Toolbar } from '../Toolbar';
import { DoctorUsers } from '../../../../pages/DoctorUsers';
import { DoctorProfiles } from '../../../../pages/DoctorProfiles';
import { Appointments } from '../../../../pages/Appointments';
import { Ads } from '../../../../pages/Ads';
import { WebUsers } from '../../../../pages/WebUsers';

const Layout = ({ performLogout, userInfo, checkIfLoggedIn }) => {
    const [sidebarOpen, setSidebarOpen] = useState(false);
    const [selectedTab, setSelectedTab] = useState('Pacientes');

    if (!userInfo) {
        checkIfLoggedIn();
    }

    return (
        <>
            <div>
                <Sidebar
                    sidebarOpen={sidebarOpen}
                    setSidebarOpen={setSidebarOpen}
                    selectedTab={selectedTab}
                    userInfo={userInfo}
                />
                <div className="flex flex-1 flex-col md:pl-64">
                    <Toolbar setSidebarOpen={setSidebarOpen} performLogout={performLogout} userInfo={userInfo} />
                    <main>
                        <Routes>
                            <Route path="*" element={<Navigate to="/users" />} />
                            <Route path="/users" element={<Users setSelectedTab={setSelectedTab} />} />
                            <Route path="/doctor-users" element={<DoctorUsers setSelectedTab={setSelectedTab} />} />;
                            <Route
                                path="/doctor-profiles"
                                element={<DoctorProfiles setSelectedTab={setSelectedTab} userInfo={userInfo} />}
                            />
                            <Route path="/appointments" element={<Appointments setSelectedTab={setSelectedTab} />} />
                            <Route path="/ads" element={<Ads setSelectedTab={setSelectedTab} />} />
                            {userInfo?.idRole === 1 && (
                                <Route path="/web-users" element={<WebUsers setSelectedTab={setSelectedTab} />} />
                            )}
                        </Routes>
                    </main>
                </div>
            </div>
        </>
    );
};

export default Layout;
