import { useState } from 'react';
import fetchAuthenticatedAdmin from '../fetchAuthenticatedAdmin';
import { getCookie, removeCookie, setCookie } from '../utils/cookie';

const API_SERVER = process.env.REACT_APP_API_SERVER;

export default function useAuthentication() {
    const [authenticatedUser, setAuthenticatedUser] = useState(null);
    const [userInfo, setUserInfo] = useState(null);

    const checkIfLoggedIn = async () => {
        const getAuthUser = await fetchAuthenticatedAdmin();

        if (getAuthUser && getAuthUser.id && getAuthUser.usertype === 3) {
            setUserInfo(getAuthUser);
        } else {
            purge();
            setUserInfo(null);
        }

        return false;
    };

    const purge = () => {
        removeCookie(process.env.REACT_APP_SESSION_NAME);
        removeCookie('usertype');
        setUserInfo(null);
    };

    const getUserType = () => {
        return getCookie('usertype');
    };

    const logoutUser = () => {
        purge();
    };

    const performLogin = async (userName, password) => {
        const attemptToLogin = await fetch(`${API_SERVER}/web-admin-data/signin`, {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ email: userName, password })
        });

        if (attemptToLogin && attemptToLogin.status < 400) {
            const loginResult = await attemptToLogin.json();
            setCookie(process.env.REACT_APP_SESSION_NAME, loginResult.access_token);
            setCookie('usertype', loginResult.usertype);
            setAuthenticatedUser(loginResult);
        }

        return attemptToLogin;
    };

    const performLogout = async () => {
        logoutUser();
    };

    return {
        authenticatedUser,
        userInfo,
        checkIfLoggedIn,
        performLogin,
        performLogout,
        getUserType
    };
}
