import {
    FormFieldsContainer,
    WhiteCardContainer,
} from '../../components';
import { ProfileFormHeader } from './components/ProfileFormHeader';
import useAppointmentHistory from './hooks/useAppointmentHistory';
import SimpleDataTable from './components/simpleDataTable/SimpleDataTable';
import HistoryAppointmentDetails from './components/appointmentDetails/HistoryAppointmentDetails';
import { useEffect } from 'react';

export default function Appointments({ setSelectedTab }) {
    
    useEffect(() => setSelectedTab('Citas'), []);

    const {
        appointmentsTableInstance,
        isLoading,
        _closeAppointmentView,
        selectedAppointment,
        startDate,
        endDate,
        setStartDate,
        setEndDate,
        selectionMode,
        _handleSuccessfulEvent
    } =
        useAppointmentHistory();

    return (
        <div className="space-y-6 sm:px-6 lg:px-0 lg:col-span-9 min-h-[80vh]">
            {/*onSubmit={handleSubmit(saveEducation)}*/}
            <WhiteCardContainer>
                {JSON.stringify(selectedAppointment) === '{}' && (
                    <FormFieldsContainer>
                        <ProfileFormHeader
                            title={'Historial de citas'}
                        />
                    </FormFieldsContainer>
                )}
                {!isLoading &&
                    appointmentsTableInstance &&
                    JSON.stringify(selectedAppointment) === '{}' && (
                        <SimpleDataTable
                          tableInstance={appointmentsTableInstance}
                          startDate={startDate}
                          endDate={endDate}
                          setEndDate={setEndDate}
                          setStartDate={setStartDate}
                        />
                    )}

                {!isLoading && JSON.stringify(selectedAppointment) !== '{}' && (
                    <HistoryAppointmentDetails
                        appointment={selectedAppointment}
                        onCancel={_closeAppointmentView}
                        selectionMode={selectionMode}
                        onSuccessfulSave={_handleSuccessfulEvent}
                    />
                )}
            </WhiteCardContainer>
        </div>
    );
}
