export default function FormSelectDrop(props) {
    const { label, identifier, autoComplete, options, helperText, registration, errorMessage, defaultValue, defaultOption=true } = props;

    return (
        <>
            <label htmlFor={identifier} className="block text-sm font-medium text-gray-700">
                {label}
            </label>
            <select
                id={identifier}
                name={identifier}
                autoComplete={autoComplete}
                defaultValue={defaultValue}
                {...registration}
                className="mt-1 block w-full bg-white border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-dl-primary-500 focus:border-dl-primary-500 sm:text-sm"
            >
                {defaultOption && (<option key="empty-select-value" value="">
                    --- Seleccione --
                </option>)}
                {options &&
                    options.map((opt) => {
                        return (
                            <option key={opt.label} value={opt.id}>
                                {opt.label}
                            </option>
                        );
                    })}
            </select>
            {!errorMessage && helperText && (
                <p className="mt-2 text-sm text-gray-500" id={`${identifier}-description`}>
                    {helperText}
                </p>
            )}
            {errorMessage ? <p className="mt-2 text-sm text-red-700">{errorMessage}</p> : null}
        </>
    );
}
