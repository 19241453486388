import { Login } from '../../pages/Login';
import './App.css';
import 'react-datepicker/dist/react-datepicker.min.css';
import { HashRouter, Routes, Route } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { useAuthentication } from '../../api';
import { PrivateRoute } from '../PrivateRoute';
import { Layout } from './components/Layout';
import { ChangePassword } from '../../pages/ChangePassword';

const App = () => {
    const { performLogout, checkIfLoggedIn, performLogin, userInfo, getUserType } = useAuthentication();

    const [isLoading, setIsLoading] = useState(true);
    const isAuth = getUserType() !== 'null';

    useEffect(() => {
        const aCall = async () => {
            await checkIfLoggedIn();

            setIsLoading(false);
        };

        aCall();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <HashRouter>
            <Routes>
                <Route path="/login" element={<Login performLogin={performLogin} />} />
                <Route path="/change-password" element={<ChangePassword performLogout={performLogout} />} />
                <Route
                    path="*"
                    element={
                        <PrivateRoute isAuth={isAuth}>
                            <Layout
                                performLogout={performLogout}
                                userInfo={userInfo}
                                checkIfLoggedIn={checkIfLoggedIn}
                            />
                        </PrivateRoute>
                    }
                />
                ;
            </Routes>
        </HashRouter>
    );
};

export default App;
