import { useForm } from 'react-hook-form';
import { useEffect, useState } from 'react';
import { format, parseISO } from 'date-fns';
import updateProfileApproval from '../api/updateProfileApproval';

export default function useProfileApproval(profileApproval, queryProfileApproval, selectedUser, userInfo, queryUsers) {
    const [isLoading, setIsLoading] = useState(true);
    const [showSuccessModal, setShowSuccessModal] = useState(false);
    const [showErrorModal, setShowErrorModal] = useState(false);
    const [errorBody, setErrorBody] = useState('');
    const [successBody, setSuccessBody] = useState('');

    const {
        register,
        handleSubmit,
        formState: { errors },
        watch,
        reset,
        setValue
    } = useForm();

    useEffect(() => {
        reset({
            ...profileApproval,
            creation: profileApproval?.creation && format(parseISO(profileApproval.creation), 'yyyy-MM-dd H:mm:ss')
        });
    }, [profileApproval, reset]);

    const _createErrorMessage = (errorMessage) => {
        setShowErrorModal(true);
        setErrorBody(errorMessage);
    };

    const closeErrorMessage = () => {
        setShowErrorModal(false);
        setErrorBody('');
    };

    const _createSuccessMessage = (errorMessage) => {
        setShowSuccessModal(true);
        setSuccessBody(errorMessage);
    };

    const onCloseSuccessMessage = () => {
        setShowSuccessModal(false);
        setSuccessBody('');
        queryProfileApproval();
        queryUsers();
    };

    const saveApproval = async (data) => {
        const executeUpdate = await updateProfileApproval(
            selectedUser.id,
            {
                ...data,
                idApproval: data.id,
                idWebUser: userInfo.id,
                status: data.status === 'true'
            },
            _createErrorMessage
        );

        if (executeUpdate) {
            _createSuccessMessage('¡Se ha actualizado el registro con éxito!');
        }
    };

    return {
        register,
        handleSubmit,
        errors,
        watch,
        reset,
        setValue,
        saveApproval,
        isLoading,
        showErrorModal,
        showSuccessModal,
        errorBody,
        closeErrorMessage,
        onCloseSuccessMessage,
        successBody
    };
}
