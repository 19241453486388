import { useEffect } from 'react';
import { WhiteCardContainer, FormFieldsContainer } from '../../components';
import useWebUsers from './hooks/useWebUsers';
import { SimpleDataTable } from './components/SimpleDataTable';
import { ProfileFormHeader } from './components/ProfileFormHeader';
import WebUserDetails from './components/WebUserDetails/WebUserDetails';
import FormButton from '../../components/formElements/FormButton';

const WebUsers = ({ setSelectedTab }) => {
    const { userTableInstance, isLoading, selectedUser, _closeUserView, onSuccessUserUpdate, onNewUser } =
        useWebUsers();

    useEffect(() => setSelectedTab('Usuarios Web'), []);

    return (
        <div className="space-y-6 sm:px-6 lg:px-0 lg:col-span-9 min-h-[80vh]">
            <WhiteCardContainer>
                {selectedUser === null && (
                    <FormFieldsContainer>
                        <ProfileFormHeader title="Usuarios Doctores" />
                        <div className="flex-row-reverse">
                            <FormButton type="button" label="Nuevo Usuario" onClick={onNewUser} />
                        </div>
                    </FormFieldsContainer>
                )}
                {!isLoading && userTableInstance && selectedUser === null && (
                    <SimpleDataTable tableInstance={userTableInstance} />
                )}

                {!isLoading && selectedUser !== null && (
                    <WebUserDetails
                        selectedUser={selectedUser}
                        onCancel={_closeUserView}
                        onSuccessfulSave={onSuccessUserUpdate}
                    />
                )}
            </WhiteCardContainer>
        </div>
    );
};

export default WebUsers;
