import WhiteCardContainer from '../../../../../components/containers/forms/WhiteCardContainer';
import FormFieldsContainer from '../../../../../components/containers/forms/FormFieldsContainer';
import ProfileFormHeader from '../../ProfileFormHeader/ProfileFormHeader';
import HalfRow from '../../../../../components/formElements/HalfRow';
import FormInputField from '../../../../../components/formElements/FormInputField';
import { CurrencyDollarIcon, MapPinIcon, PhoneIcon } from '@heroicons/react/24/solid';
import FormFieldset from '../../../../../components/formElements/FormFieldset';
import FormCheckBox from '../../../../../components/formElements/FormCheckBox';
import FullRow from '../../../../../components/formElements/FullRow';
import FormTextArea from '../../../../../components/formElements/FormTextArea';
import FormSelectDrop from '../../../../../components/formElements/FormSelectDrop';
import FormFieldsFooter from '../../../../../components/containers/forms/FormFieldsFooter';
import FormButton from '../../../../../components/formElements/FormButton';
import CustomRedAlert from '../../../../../components/alerts/customRedAlert/CustomRedAlert';
import CustomSuccessModal from '../../../../../components/alerts/customSuccessModal/CustomSuccessModal';
import useProfileApproval from './hooks/useProfileApproval';

export default function ProfileApproval({ profileApproval, queryProfileApproval, selectedUser, userInfo, queryUsers }) {
    const {
        handleSubmit,
        errors,
        register,
        saveApproval,
        showErrorModal,
        showSuccessModal,
        errorBody,
        closeErrorMessage,
        onCloseSuccessMessage,
        successBody
    } = useProfileApproval(profileApproval, queryProfileApproval, selectedUser, userInfo, queryUsers);

    if (!profileApproval) {
        return null;
    }
    return (
        <form action="#" method="POST" onSubmit={handleSubmit(saveApproval)}>
            <WhiteCardContainer>
                <FormFieldsContainer>
                    <ProfileFormHeader
                        title={'Aprobar el perfil del doctor'}
                        initialText={'Si toda la información del doctor esta correcta, puedes proceder a aprobarlo'}
                    />

                    <div className="grid grid-cols-6 gap-6">
                        <HalfRow>
                            <FormInputField
                                identifier={'profileApprovalTitle'}
                                label={'Título de la aprobación'}
                                type={'text'}
                                placeholder={'Ej. Aprobación para el doctor'}
                                helperText={'Requerido'}
                                registration={register('profileApprovalTitle', { required: true })}
                                errorMessage={errors.profileApprovalTitle ? 'Este campo es requerido' : null}
                            />
                        </HalfRow>

                        <FullRow>
                            <FormTextArea
                                label={'Descripción de la aprobación'}
                                identifier={'profileApprovalDescription'}
                                rows={3}
                                placeholder={'Ej. Describir porque se da esta aprobación a este doctor'}
                                helperText={
                                    'Información relevante sobre el por que se le permite al médico ser aprobado.'
                                }
                                registration={register('profileApprovalDescription', { required: true })}
                                errorMessage={errors.profileApprovalDescription ? 'Este campo es requerido' : null}
                            />
                        </FullRow>

                        <FullRow>
                            <FormTextArea
                                label={'Notas internas sobre este doctor.'}
                                identifier={'notes'}
                                rows={3}
                                placeholder={'Ej. Este doctor falta revisar algo nuevo'}
                                helperText={'Notas internas para este perfil.'}
                                registration={register('notes', { required: true })}
                                errorMessage={errors.notes ? 'Este campo es requerido' : null}
                            />
                        </FullRow>

                        <HalfRow>
                            <FormInputField
                                identifier="creation"
                                label="Creación"
                                type="string"
                                registration={register('creation', {
                                    required: true
                                })}
                                disabled
                            />
                        </HalfRow>

                        <HalfRow>
                            <FormInputField
                                label={'Última actualización hecha por Admin:'}
                                type={'text'}
                                value={`${profileApproval.webUserDetails.firstName} ${profileApproval.webUserDetails.lastName} - ${profileApproval.webUserDetails.email}`}
                                identifier={'webUser'}
                                disabled
                            />
                        </HalfRow>
                        <HalfRow />

                        <HalfRow>
                            <FormSelectDrop
                                label={'Estado de la aprobación del perfil'}
                                identifier={'status'}
                                options={[
                                    { id: true, label: 'Activo' },
                                    { id: false, label: 'Inactivo' }
                                ]}
                                defaultValue={profileApproval.status}
                                defaultOption={false}
                                helperText={'Requerido'}
                                registration={register('status', { required: true })}
                                errorMessage={errors.status ? 'Este campo es requerido' : null}
                            />
                        </HalfRow>
                    </div>
                </FormFieldsContainer>
                <FormFieldsFooter>
                    <FormButton label="Actualizar" type={'submit'} />
                </FormFieldsFooter>
            </WhiteCardContainer>
            {showErrorModal && (
                <CustomRedAlert
                    open={true}
                    title={`Ocurrió un error.`}
                    bodyText={errorBody}
                    onClose={closeErrorMessage}
                />
            )}

            {showSuccessModal && (
                <CustomSuccessModal
                    title={'Aprobación actualizada con éxito'}
                    bodyText={successBody}
                    buttonText={'Actualizar Aprobación'}
                    buttonAction={onCloseSuccessMessage}
                    open={showSuccessModal}
                    onClose={onCloseSuccessMessage}
                />
            )}
        </form>
    );
}
