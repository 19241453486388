import { useEffect, useState } from 'react';
import useDoctorUserProfile from '../../hooks/useDoctorUserProfile';
import CustomRedAlert from '../../../../components/alerts/customRedAlert/CustomRedAlert';
import InitialProfileForm from './initialProfileForm/InitialProfileForm';
import DoctorProfileTabs from './doctorProfileTabs/DoctorProfileTabs';
import SecondarySpecialties from './secondarySpecialties/SecondarySpecialties';
import ProfileLanguages from './languages/ProfileLanguages';
import DoctorExperience from './experience/DoctorExperience';
import DoctorEducation from './education/DoctorEducation';
import DoctorMotives from './motives/DoctorMotives';
import DoctorSchedules from './schedules/DoctorSchedules';
import DoctorAvailability from './availability/DoctorAvailability';
import { XMarkIcon } from '@heroicons/react/20/solid';
import ProfileApproval from './profileApproval/ProfileApproval';

export default function DoctorOwnProfile({ selectedUser, onClose, userInfo, queryUsers }) {
    const {
        basicProfile,
        errorMessage,
        setErrorMessage,
        cities,
        isLoading,
        specialities,
        queryBasicProfile,
        secondarySpecialties,
        formattedSecondarySpecialties,
        querySecondarySpecialities,
        queryLanguagesInProfile,
        languagesInProfile,
        formattedLanguages,
        queryAllDoctorExperiences,
        experiences,
        queryAllDoctorEducation,
        education,
        motives,
        queryAllDoctorMotives,
        schedules,
        queryAllDoctorSchedules,
        availabilities,
        queryAllDoctorAvailabilities,
        schedulesAsOptions,
        profileApproval,
        queryProfileApproval
    } = useDoctorUserProfile(selectedUser);

    const [currentComponent, setCurrentComponent] = useState('Perfil');

    return (
        <div className="space-y-6 sm:px-6 lg:px-0 lg:col-span-9 min-h-[calc(100vh_-_306px)] mb-56">
            <div className="m-2">
                <button
                    type="button"
                    className="order-0 inline-flex items-center rounded-md border border-transparent bg-dl-red px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-red-500 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2 sm:order-1 sm:ml-3"
                    onClick={onClose}
                >
                    Regresar a lista
                </button>
                <h3 className={`text-lg leading-6 font-medium px-4 py-10`}>{`Examinando perfil de Dr(a):  ${selectedUser.firstName} ${selectedUser.lastName}`}</h3>
            </div>
            {basicProfile && basicProfile.hasProfile && (
                <DoctorProfileTabs
                    selectedItem={currentComponent}
                    updateNavigation={(newComponent) => setCurrentComponent(newComponent)}
                />
            )}

            {!isLoading && currentComponent === 'Perfil' && (
                <InitialProfileForm
                    cities={cities}
                    basicProfile={basicProfile}
                    specialities={specialities}
                    queryBasicProfile={queryBasicProfile}
                    selectedUser={selectedUser}
                />
            )}

            {!isLoading && currentComponent === 'Especialidades' && (
                <SecondarySpecialties
                    basicProfile={basicProfile}
                    secondarySpecialties={secondarySpecialties}
                    formattedSecondarySpecialties={formattedSecondarySpecialties}
                    querySecondarySpecialities={querySecondarySpecialities}
                    selectedUser={selectedUser}
                />
            )}

            {!isLoading && currentComponent === 'Idioma' && (
                <ProfileLanguages
                    basicProfile={basicProfile}
                    languagesInProfile={languagesInProfile}
                    formattedLanguages={formattedLanguages}
                    queryLanguagesInProfile={queryLanguagesInProfile}
                    selectedUser={selectedUser}
                />
            )}

            {!isLoading && currentComponent === 'Experiencia' && (
                <DoctorExperience
                    basicProfile={basicProfile}
                    experiences={experiences}
                    queryAllDoctorExperiences={queryAllDoctorExperiences}
                    selectedUser={selectedUser}
                />
            )}

            {!isLoading && currentComponent === 'Educación' && (
                <DoctorEducation
                    education={education}
                    queryAllDoctorEducation={queryAllDoctorEducation}
                    selectedUser={selectedUser}
                />
            )}

            {!isLoading && currentComponent === 'Motivos' && (
                <DoctorMotives
                    motives={motives}
                    queryAllDoctorMotives={queryAllDoctorMotives}
                    selectedUser={selectedUser}
                />
            )}

            {!isLoading && currentComponent === 'Horarios' && (
                <DoctorSchedules
                    schedules={schedules}
                    queryAllDoctorSchedules={queryAllDoctorSchedules}
                    selectedUser={selectedUser}
                />
            )}

            {!isLoading && currentComponent === 'Citas' && (
                <DoctorAvailability
                    schedulesAsOptions={schedulesAsOptions}
                    schedules={schedules}
                    queryAllDoctorAvailabilities={queryAllDoctorAvailabilities}
                    availabilities={availabilities}
                    selectedUser={selectedUser}
                />
            )}

            {!isLoading && currentComponent === 'Aprobar' && profileApproval && (
                <ProfileApproval
                    profileApproval={profileApproval}
                    queryProfileApproval={queryProfileApproval}
                    selectedUser={selectedUser}
                    userInfo={userInfo}
                    queryUsers={queryUsers}
                />
            )}

            {Boolean(errorMessage) && (
                <CustomRedAlert
                    open={true}
                    title={`Ocurrió un error.`}
                    bodyText={errorMessage}
                    onClose={() => setErrorMessage(null)}
                />
            )}
        </div>
    );
}
