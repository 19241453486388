import { useState } from 'react';
import { ProfileFormHeader } from '../ProfileFormHeader';
import HalfRow from '../../../../components/formElements/HalfRow';
import FormInputField from '../../../../components/formElements/FormInputField';
import FullRow from '../../../../components/formElements/FullRow';
import useAdsDetails from './hooks/useAdsDetails';
import CustomRedAlert from '../../../../components/alerts/customRedAlert/CustomRedAlert';
import CustomSuccessModal from '../../../../components/alerts/customSuccessModal/CustomSuccessModal';
import { CalendarIcon, PhoneIcon, UsersIcon, EnvelopeIcon } from '@heroicons/react/24/outline';
import FormSelectDrop from '../../../../components/formElements/FormSelectDrop';

export default function AdsDetails(props) {
    const { selectedAd, onCancel, onSuccessfulSave } = props;

    const [openReturnAlert, setOpenReturnAlert] = useState(false);

    const {
        handleSubmit,
        register,
        errors,
        isDirty,
        closeErrorMessage,
        onCloseSuccessMessage,
        showSuccessModal,
        showErrorModal,
        errorBody,
        successBody,
        onSaveSubmit,
        isEditing
    } = useAdsDetails(selectedAd, onSuccessfulSave);

    const _onReturnToAds = () => {
        if (isDirty) {
            setOpenReturnAlert(true);
        } else {
            onCancel();
        }
    };

    const _onConfirmCancelAppointment = () => {
        setOpenReturnAlert(false);
        onCancel();
    };

    const _onCancelConfirmationForClosing = () => {
        setOpenReturnAlert(false);
    };

    return (
        <form onSubmit={handleSubmit(onSaveSubmit)}>
            <div className="shadow sm:rounded-md sm:overflow-hidden">
                <div className="bg-white py-6 px-4 space-y-6 sm:p-6">
                    {!isEditing ? (
                        <ProfileFormHeader title={'Crear nuevo anuncio'} />
                    ) : (
                        <ProfileFormHeader title={'Editar anuncio'} />
                    )}
                    <div>
                        <h3 className="text-lg leading-6 font-medium text-dl-secondary">
                            {isEditing ? 'Edición de anuncio' : 'Nuevo registro'}
                        </h3>
                    </div>
                    <div className="grid grid-cols-6 gap-6">
                        <HalfRow>
                            <FormInputField
                                identifier="name"
                                label="Nombre del Anuncio"
                                type="string"
                                registration={register('name', {
                                    required: true
                                })}
                            />
                        </HalfRow>
                        <FullRow>
                            <FormInputField
                                identifier="link"
                                label="Vínculo o Link"
                                type="string"
                                registration={register('link', {
                                    required: true
                                })}
                            />
                        </FullRow>
                        <FullRow>
                            <FormInputField
                                identifier="imageUrl"
                                label="URL de imagen"
                                type="string"
                                registration={register('imageUrl', {
                                    required: true
                                })}
                                helperText={`Imagen default: ${process.env.REACT_APP_FRONTEND_SERVER}/defaultIcons/BannerApp.jpg`}
                            />
                        </FullRow>
                        {isEditing && (
                            <HalfRow>
                                <FormInputField
                                    identifier="creation"
                                    label="Creación"
                                    type="string"
                                    registration={register('creation', {
                                        required: true
                                    })}
                                    disabled
                                />
                            </HalfRow>
                        )}
                        <HalfRow>
                            <FormSelectDrop
                                label={'Estado del anuncio'}
                                identifier={'status'}
                                options={[
                                    { id: true, label: 'Activo' },
                                    { id: false, label: 'Inactivo' }
                                ]}
                                defaultValue={selectedAd.status.toString()}
                                defaultOption={false}
                                helperText={'Requerido'}
                                registration={register('status', { required: true })}
                                errorMessage={errors.status ? 'Este campo es requerido' : null}
                            />
                        </HalfRow>
                    </div>
                </div>
                <div className="px-4 py-3 bg-gray-50 text-right sm:px-6">
                    <button
                        type="button"
                        onClick={_onReturnToAds}
                        className="mr-2 rounded-full border border-gray-300 bg-white py-2 px-4 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-dl-accent focus:ring-offset-2"
                    >
                        Regresar
                    </button>

                    <button
                        type="submit"
                        disabled={!isDirty}
                        className="disabled:bg-dl-primary-700 bg-dl-primary-900 border border-transparent rounded-full shadow-sm py-2 px-4 inline-flex justify-center text-sm font-medium text-white hover:bg-dl-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-dl-primary-700"
                    >
                        {!isEditing ? 'Guardar' : 'Actualizar'}
                    </button>
                </div>
            </div>

            {openReturnAlert && (
                <CustomRedAlert
                    open={openReturnAlert}
                    title={'¿Desea descartar los cambios?'}
                    bodyText={'Los datos han sido modificados, por favor confirme que desea salir de esta vista.'}
                    onClose={_onCancelConfirmationForClosing}
                    mainActionText={'Salir'}
                    onMainAction={_onConfirmCancelAppointment}
                    secondaryActionText={'Cancelar'}
                    onSecondaryAction={_onCancelConfirmationForClosing}
                />
            )}

            {showErrorModal && (
                <CustomRedAlert
                    open={showErrorModal}
                    title={'Ocurrió un error.'}
                    bodyText={errorBody}
                    onClose={closeErrorMessage}
                />
            )}

            {showSuccessModal && (
                <CustomSuccessModal
                    title={'Registro actualizado con éxito'}
                    bodyText={successBody}
                    buttonText={'Aceptar'}
                    buttonAction={onCloseSuccessMessage}
                    open={showSuccessModal}
                    onClose={onCloseSuccessMessage}
                />
            )}
        </form>
    );
}
