import { useEffect } from 'react';
import useAds from './hooks/useAds';
import { WhiteCardContainer, FormFieldsContainer } from '../../components';
import { SimpleDataTable } from './components/SimpleDataTable';
import { ProfileFormHeader } from './components/ProfileFormHeader';
import AdsDetails from './components/AdsDetails/AdsDetails';
import FormButton from '../../components/formElements/FormButton';

const Ads = ({ setSelectedTab }) => {
    const { adsTableInstance, isLoading, selectedAd, _closeUserView, onSuccessAdUpdate, onNewAd } = useAds();

    useEffect(() => setSelectedTab('Anuncios'), []);

    return (
        <div className="space-y-6 sm:px-6 lg:px-0 lg:col-span-9 min-h-[80vh]">
            <WhiteCardContainer>
                {selectedAd === null && (
                    <FormFieldsContainer>
                        <ProfileFormHeader title="Anuncios" />
                        <div className="flex-row-reverse">
                            <FormButton type="button" label="Nuevo Anuncio" onClick={onNewAd} />
                        </div>
                    </FormFieldsContainer>
                )}
                {!isLoading && adsTableInstance && selectedAd === null && (
                    <SimpleDataTable tableInstance={adsTableInstance} />
                )}

                {!isLoading && selectedAd !== null && (
                    <AdsDetails
                        selectedAd={selectedAd}
                        onCancel={_closeUserView}
                        onSuccessfulSave={onSuccessAdUpdate}
                    />
                )}
            </WhiteCardContainer>
        </div>
    );
};

export default Ads;
