import { WhiteCardContainer, FormFieldsContainer } from '../../components';
import useUsers from './hooks/useUsers';
import { SimpleDataTable } from './components/SimpleDataTable';
import { ProfileFormHeader } from './components/ProfileFormHeader';
import UserDetails from './components/UserDetails/UserDetails';
import { useEffect } from 'react';

const Users = ({ setSelectedTab }) => {
    const {
        userTableInstance,
        isLoading,
        selectedUser,
        _closeUserView,
        userStatusCatalog,
        documentTypes,
        onSuccessUserUpdate
    } = useUsers();

    useEffect(() => setSelectedTab('Pacientes'), []);

    return (
        <div className="space-y-6 sm:px-6 lg:px-0 lg:col-span-9 min-h-[80vh]">
            <WhiteCardContainer>
                {JSON.stringify(selectedUser) === '{}' && (
                    <FormFieldsContainer>
                        <ProfileFormHeader title="Pacientes" />
                    </FormFieldsContainer>
                )}
                {!isLoading && userTableInstance && JSON.stringify(selectedUser) === '{}' && (
                    <SimpleDataTable tableInstance={userTableInstance} />
                )}

                {!isLoading && JSON.stringify(selectedUser) !== '{}' && (
                    <UserDetails
                        selectedUser={selectedUser}
                        onCancel={_closeUserView}
                        onSuccessfulSave={onSuccessUserUpdate}
                        selectionMode={'edit'}
                        userStatusCatalog={userStatusCatalog}
                        documentTypes={documentTypes}
                    />
                )}
            </WhiteCardContainer>
        </div>
    );
};

export default Users;
