import { WhiteCardContainer, FormFieldsContainer } from '../../components';
import useDoctorUsers from './hooks/useDoctorUsers';
import { SimpleDataTable } from './components/SimpleDataTable';
import { ProfileFormHeader } from './components/ProfileFormHeader';
import DoctorUserDetails from './components/DoctorUserDetails/DoctorUserDetails';
import { useEffect } from 'react';

const DoctorUsers = ({ setSelectedTab }) => {
    const {
        userTableInstance,
        isLoading,
        selectedUser,
        _closeUserView,
        userStatusCatalog,
        documentTypes,
        onSuccessUserUpdate
    } = useDoctorUsers();

    useEffect(() => setSelectedTab('Doctores'), []);

    return (
        <div className="space-y-6 sm:px-6 lg:px-0 lg:col-span-9 min-h-[80vh]">
            <WhiteCardContainer>
                {JSON.stringify(selectedUser) === '{}' && (
                    <FormFieldsContainer>
                        <ProfileFormHeader title="Usuarios Doctores" />
                    </FormFieldsContainer>
                )}
                {!isLoading && userTableInstance && JSON.stringify(selectedUser) === '{}' && (
                    <SimpleDataTable tableInstance={userTableInstance} />
                )}

                {!isLoading && JSON.stringify(selectedUser) !== '{}' && (
                    <DoctorUserDetails
                        selectedUser={selectedUser}
                        onCancel={_closeUserView}
                        onSuccessfulSave={onSuccessUserUpdate}
                        selectionMode={'edit'}
                        userStatusCatalog={userStatusCatalog}
                        documentTypes={documentTypes}
                    />
                )}
            </WhiteCardContainer>
        </div>
    );
};

export default DoctorUsers;
