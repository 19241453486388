import { WhiteCardContainer, FormFieldsContainer } from '../../components';
import useDoctorProfiles from './hooks/useDoctorProfiles';
import { SimpleDataTable } from './components/SimpleDataTable';
import { ProfileFormHeader } from './components/ProfileFormHeader';
import DoctorUserDetails from './components/DoctorUserDetails/DoctorUserDetails';
import { useEffect } from 'react';
import DoctorOwnProfile from './components/doctorOwnProfile/DoctorOwnProfile';

const DoctorProfiles = ({ setSelectedTab, userInfo }) => {
    const { userTableInstance, isLoading, selectedUser, _closeUserView, queryUsers } = useDoctorProfiles();

    useEffect(() => setSelectedTab('Perfiles Dr.'), []);

    return (
        <div className="space-y-6 sm:px-6 lg:px-0 lg:col-span-9 min-h-[80vh]">
            <WhiteCardContainer>
                {JSON.stringify(selectedUser) === '{}' && (
                    <FormFieldsContainer>
                        <ProfileFormHeader title="Perfiles de Doctores" />
                    </FormFieldsContainer>
                )}
                {!isLoading && userTableInstance && JSON.stringify(selectedUser) === '{}' && (
                    <SimpleDataTable tableInstance={userTableInstance} />
                )}

                {!isLoading && JSON.stringify(selectedUser) !== '{}' && (
                    <DoctorOwnProfile
                        selectedUser={selectedUser}
                        onClose={_closeUserView}
                        userInfo={userInfo}
                        queryUsers={queryUsers}
                    />
                )}
            </WhiteCardContainer>
        </div>
    );
};

export default DoctorProfiles;
